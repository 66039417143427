import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  padding: 0 20px;

  ${breakpoint.medium`
    padding: 0 20px;
  `}

`

const StyledRespiVideos = styled.div`
  display: ${props => (props.viewAllowed ? "block" : "none")};
  background-color: ${props => (props.grayLight ? colors.grayLight : colors.white)};
  position: relative;
  padding: 0 0 130px;
  border-bottom-width: 0;
  overflow: hidden;

  h3 {
    text-align: center;
    line-height: 1.2em;
    font-size: 27px;

    ${breakpoint.medium`
     text-align: left;
    `}
  }

  h4 {
		font-size: 21px;
		margin-block: 10px;
		border-bottom: 1px solid ${colors.purpleLightBg};
		padding-bottom: 5px;		
	}

  p { 
    font-size: 17px; 
    line-height: 1.3em;
    text-align: center;
  
    ${breakpoint.medium`
    text-align: left;
    `}
  }

  .quick-results__heading {
    padding-bottom: 1rem;

    .timeVideo {
      font-weight: 400!important;
      font-size: 15px;
    }
  
  }

.column {
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
  flex-wrap: wrap;
  gap: 60px 20px;

  ${breakpoint.medium`
    flex-wrap: nowrap;
    gap: 0;
  `}

  ${breakpoint.extraLarge`
    gap: 60px;
  `}
}

`

const StyledVideo = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 56%;

    &::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 15px;
		left: 15px;
		background-image: radial-gradient(${colors.grayDark} 0.05em,transparent 0.08em),radial-gradient(${colors.grayDark} 0.05em,transparent 0.08em);
		background-size: 12px 12px !important;
		background-position: 0 0, 18px 18px;
		background-repeat: repeat;
		opacity: 0.3;
		z-index: 2;


      ${breakpoint.medium`
        top: 20px;
        left: 20px;
      `}
    }

  .video__placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${props => (props.showPlaceholder ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 500;
    border: 1px solid ${colors.grayLight};

    &:hover {
      border: 1px solid ${colors.grayDark};
    }
  }

  .video__placeholderFull {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${props => (props.showPlaceholderFull ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 500;
    border: 1px solid ${colors.grayLight};

    &:hover {
      border: 1px solid ${colors.grayDark};
    }
  }


  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 499;
  }


`

const Video = props => {

  return (
      <StyledVideo
        onClick={props.onClick}
        showPlaceholder={props.showPlaceholder}
        onKeyDown={props.onClick}
        tabIndex="0"
      >
        <StaticImage
          src="../../../assets/images/respiratory-health-test/video-placeholder.jpg"
          width={900}
          alt="How to use the Visby Medical test"
          className="video__placeholder"
          style={{
            position: "absolute",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <iframe
          title="How to use the Visby Medical test"
          src={
            props.showPlaceholder
            ? "https://www.youtube.com/embed/VXM9UMVsG5s"
            : "https://www.youtube.com/embed/VXM9UMVsG5s?autoplay=1"
            }
          allow="autoplay; fullscreen"
          tabIndex="-1"
        ></iframe>

      </StyledVideo>
  )
}

const VideoFull = props => {

  return (
      <StyledVideo
        onClick={props.onClick}
        showPlaceholderFull={props.showPlaceholderFull}
        onKeyDown={props.onClick}
        tabIndex="0"
      >
        <StaticImage
          src="../../../assets/images/respiratory-health-test/video-placeholder.jpg"
          width={900}
          alt="How to use the Visby Medical test"
          className="video__placeholderFull"
          style={{
            position: "absolute",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <iframe
          title="How to use the Visby Medical test"
          src={
            props.showPlaceholderFull
              ? "https://www.youtube.com/embed/2Oo-g_JB_NI"
              : "https://www.youtube.com/embed/2Oo-g_JB_NI?autoplay=1"
          }
          allow="autoplay; fullscreen"
          tabIndex="-1"
        ></iframe>

      </StyledVideo>
  )
}


class RespiVideos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPlaceholder: true,
      showPlaceholderFull: true,
    }
  }

  hidePlaceholder = () => {
    if (this.state.showPlaceholder) {
      this.setState({
        showPlaceholder: false,
      })
    }
  }

  hidePlaceholderFull = () => {
    if (this.state.showPlaceholderFull) {
      this.setState({
        showPlaceholderFull: false,
      })
    }
  }
  

  render = props => (
    <StyledRespiVideos
      viewAllowed={this.props.viewAllowed}
      data-filter="respiratory" data-filter-type="devices" data-section={this.props.section}
    >
      <div className="column">
      <StyledContainer>
        <div className="quick-results__heading" id="visby-personal-pcr">
          <h4>Respiratory Health Test - Intro Video<br /><span className="timeVideo">(46 seconds)</span></h4>
        </div>

        <div><Video
          showPlaceholder={this.state.showPlaceholder}
          onClick={this.hidePlaceholder}
        /></div>

      </StyledContainer>

      <StyledContainer>
        <div className="quick-results__heading" id="visby-personal-pcr2">
          <h4>Respiratory Health Test - Tutorial Video<br /><span className="timeVideo">(3:51 minutes)</span></h4>
        </div>

        <div><VideoFull
          showPlaceholderFull={this.state.showPlaceholderFull}
          onClick={this.hidePlaceholderFull}
        /></div>

      </StyledContainer>
      </div>

    </StyledRespiVideos>
  )
}

export default RespiVideos
